<template src="./index.html" />

<script>
import {
  VBTooltip,
  ModalPlugin,

  BBreadcrumb, BButton, BCard, BCol, BContainer, BFormSelect, BOverlay, BPagination, BRow,
} from 'bootstrap-vue'

import FormEmployee from '@/views/admin/category/employee/FormEmployee.vue'
import { VueGoodTable } from 'vue-good-table'
import { STATUSES } from '@/const/status'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import { getUser } from '@/auth/utils'
import { hasPermissionForResource, isSystemAdmin } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'

export default {
  name: 'Employee',
  components: {
    BBreadcrumb,
    BButton,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    VueGoodTable,
    ModalPlugin,
    VBTooltip,
    vSelect,
    FormEmployee,
  },
  props: {},
  data() {
    return {
      user: getUser(),
      serverParams: {
        page: 1,
        itemsPerPage: 10,
        searchTerm: null,
        departmentId: null,
        status: null,
        organizationId: getUser().orgId,
      },
      totalRecords: 0,
      itemsDisplay: 0,
      items: [],
      statuses: [],
      table: {
        fields: [
          {
            field: 'fullName',
            label: 'Tên nhân viên',
            sortable: false,
            type: 'string',
            filterOptions: {
              placeholder: 'Tên...',
              enabled: true,
              trigger: 'enter',
            },
          },
          {
            field: 'username', label: 'Tài khoản', sortable: false, type: 'string',
          },
          {
            field: 'titleName', label: 'Chức danh', sortable: false, type: 'string',
          },
          {
            field: 'departmentId',
            label: 'Khoa/bộ môn',
            sortable: false,
            type: 'string',
            filterOptions: {
              placeholder: 'Tất cả',
              enabled: true,
              trigger: 'enter',
              filterDropdownItems: [],
            },
          },
          {
            label: 'Trạng thái',
            field: 'status',
            filterOptions: {
              enabled: true,
              trigger: 'enter',
              filterDropdownItems: STATUSES.map(status => ({ value: status.value, text: status.label })),
              placeholder: 'Chọn trạng thái',
            },
            sortable: false,
            thClass: 'text-center',
          },
          {
            field: 'action', label: 'Hành động', sortable: false, tdClass: 'text-center',
          },
        ],
        items: [],
      },
      dataSendForm: {},
    }
  },
  computed: {
    // calculate data from init data
    ...mapGetters({
      employees: 'employee/employees',
      total: 'employee/total',
      titles: 'employee/titles',
      departments: 'employee/departments',
    }),
    creatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.CREATE, ResourceCode.EMPLOYEE)
    },
    updatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.EMPLOYEE)
    },
    deletable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.DELETE, ResourceCode.EMPLOYEE)
    },
  },
  async created() {
    // init data FROM API
    await this.getDepartments({
      currentPage: 1,
      itemsPerPage: 1000,
      status: 1,
      organizationId: this.user.orgId,
    })
    const optDepartment = this.departments.map(item => ({ value: item.id, text: item.name }))
    this.$set(this.table.fields[4].filterOptions, 'filterDropdownItems', optDepartment)
    await this.getTitles({})
    await this.getData()
  },
  methods: {
    ...mapActions({
      getEmployee: 'employee/getEmployee',
      createEmployee: 'employee/createEmployee',
      updateEmployee: 'employee/updateEmployee',
      deleteEmployee: 'employee/deleteEmployee',
      getTitles: 'employee/getTitles',
      getDepartments: 'employee/getDepartments',
    }),
    openModal() {
      this.dataSendForm = undefined
      this.$bvModal.show('modal-employee')
    },

    edit(data) {
      this.dataSendForm = data
      this.$bvModal.show('modal-employee')
    },

    deleteRow(data) {
      this.$swal({
        title: `Bạn chắc chắn muốn xóa cán bộ <span class="text-danger">${data.fullName}</span>?`,
        text: 'Bạn sẽ không thể khôi phục lại dữ liệu này!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.isConfirmed) {
          this.loading = true
          try {
            const response = await this.deleteEmployee(data.id)
            if (response) {
              const { isSuccessful, message } = response
              if (isSuccessful) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                try {
                  await this.getData(this.serverParams)
                } catch (e) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: `[${e.code}] ${e.message}`,
                      icon: 'XCircleIcon',
                      variant: 'danger',
                    },
                  })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.loading = false
          }
        }
      })
    },

    async getData() {
      await this.getEmployee(this.serverParams)
      this.table.items = this.employees
      this.totalRecords = this.total
    },

    async saveDataForm(value) {
      this.dataSendForm = value
      try {
        const response = this.dataSendForm.id
          ? await this.updateEmployee(this.dataSendForm)
          : await this.createEmployee(this.dataSendForm)
        if (response) {
          const { isSuccessful, message } = response
          if (isSuccessful) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            await this.getData()
            this.$bvModal.hide('modal-employee')
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          }
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
    },

    getStatusName(id) {
      return STATUSES.find(status => status.value === id).label
    },

    getDepartmentName(id) {
      if (!id) return ''
      const found = this.departments.find(department => department.id === id)
      return found ? found.name : ''
    },

    showToast(title, icon, variant, text) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
          text,
        },
      })
    },

    // handle pagination
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
    },

    async onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      await this.getData()
    },

    async onPerPageChange(params) {
      this.updateParams({ itemsPerPage: params.currentPerPage })
      await this.getData()
    },

    async onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field,
          },
        ],
      })
      await this.getData()
    },

    async onColumnFilter(params) {
      const { columnFilters } = params
      if (Object.hasOwn(columnFilters, 'fullName')) this.updateParams({ searchTerm: columnFilters.fullName.trim() })
      if (Object.hasOwn(columnFilters, 'departmentId')) this.updateParams({ departmentId: columnFilters.departmentId })
      if (Object.hasOwn(columnFilters, 'status')) this.updateParams({ status: columnFilters.status })
      await this.getData()
    },
  },
}
</script>
